import React, { PureComponent } from 'react';
import { AuthContext } from '../auth/AuthContext';
import Button from './../common-elements/Button';



export class FullscreenButton extends PureComponent {




	static contextType = AuthContext;

	fullscreenChange = (event) => {
		
		if (!document.fullscreenElement) {
			this.setState({ isFullScreen: false });
		} else {
			this.setState({ isFullScreen: true });
		}
	};

	constructor(props) {
		super(props);
		this.fullscreenChange = this.fullscreenChange.bind(this);

		this.state = { isFullScreen: false };
		document.addEventListener('fullscreenchange', this.fullscreenChange);

	}


	componentWillUnmount() {

	}



	render() {


		return (
			this.context.authenticated ?


				<Button onClick={
					() => {

						this.props.unityContent.setFullscreen(true);

					}
				}>
					{this.state.isFullScreen ? <React.Fragment>go to window</React.Fragment> : <React.Fragment>Fullscreen</React.Fragment>}
				</Button >
				: null



		);
	}


}