import React, { PureComponent } from 'react';
import { AuthContext } from './AuthContext';
import { firebaseAuthHelper } from './firebaseAuthHelpers';
import { provider } from './firebaseProvider';

import { RoundedBigButton } from '../common-elements/Button.js';

export class Login extends PureComponent {

	static contextType = AuthContext;
	onAuthStateChangeSub = null;
	intervalId = null;
	constructor() {
		super();
		this.unsubscribeToAuthStateChange();
		this.onAuthStateChangeSub = firebaseAuthHelper.onAuthStateChanged((user) => {
			if (typeof this.props.onSignIn === 'function') {
				if (user) {
					firebaseAuthHelper.getLightUser().then((user2) => {
						this.props.onSignIn(user2);

					});
				} else {
					this.props.onSignIn(null);
				}
			}
		}, (e) => {
			if (typeof this.props.onSignInError === 'function') {
				this.props.onSignInError(e);
			}
		});

	}

	componentDidCatch(error, info) {
		console.error(error, info);
	  }

	componentWillUnmount() {
		this.unsubscribeToAuthStateChange();
	}

	unsubscribeToAuthStateChange() {
		if (this.onAuthStateChangeSub) {
			this.onAuthStateChangeSub();
		}
	}


	signIn() {

		firebaseAuthHelper.signIn(provider).then(() => {
			return firebaseAuthHelper.getLightUser().then((lightUser) => {

				if (typeof this.props.onSignIn === 'function') {

					this.props.onSignIn(lightUser);
				}
			});

		})

			.catch((e) => {
				console.error(e);
				if (typeof this.props.onSignInError === 'function') {
					this.props.onSignInError(e);
				}
			});
	}




	handleFakeRefresh(lightUser) {
		if (typeof this.props.onRefreshToken === 'function') {
			this.props.onRefreshToken(lightUser);
		}
	}

	render() {
		return (!this.context.authenticated ?
			(


				<RoundedBigButton onClick={() => { this.signIn() }}>LOGIN</RoundedBigButton>


			) :
			(
				null

			)
		);
	}

}