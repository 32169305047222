
import React from 'react';

import Countdown from 'react-countdown';
import FormattedCountdown from './FormattedCountdown';
import styled from 'styled-components'
const StartsIn = styled.div`
	display: flex; 
	flex-direction: row;
	justify-content: center;
	
	position: relative;
	padding-top: 100px;
	color: #FFF;
	font-size: 70px;
	margin-bottom: 15px;
	
`;


const MalibuCountdown = (props) => {

	const complete = () => {
		if (typeof props.onEventStarted === 'function') {
			props.onEventStarted();
		}
	};
	let dateToCountDownTo = new Date(Date.UTC(2021, 10, 29, 7, 0, 0, 0));
	if (props.complete) {
		dateToCountDownTo = new Date();
	}
	// const countdownRef = React.createRef();
	
	// const now = new Date();
	// if (dateToCountDownTo.getTime() <= now.getTime()) {
	// 	complete();
	// }



	return (
		<React.Fragment>
			<StartsIn>
				<span>Starts in</span>
			</StartsIn>
			<Countdown
				date={dateToCountDownTo}
				intervalDelay={0}
				precision={3}
				renderer={props => <FormattedCountdown {...props} onComplete={() => complete()}> </FormattedCountdown>}
				onComplete={() => complete()}
			/>
		</React.Fragment>

	);
}

export default MalibuCountdown;