import styled from 'styled-components'
import React from 'react';
const HeaderOverGameContainer = styled.div`
	
	
	font-size: 60px;
	display:flex;
	flex-direction: row;
	justify-content: center;
	color: #FFF;
	font-family: 'Malibu Handwritten 3', 'Arial';

`;


const HeaderOverGame = (props) => {
	return (
		<React.Fragment>
			<HeaderOverGameContainer>
				{props.children}
			</HeaderOverGameContainer>
		</React.Fragment>

	);
}



export default HeaderOverGame;