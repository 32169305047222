import React from 'react';

import NewWindowhandler from '../unityLoader/NewWindowHandler';

const openWindows = [];

const NewWindow = () => {
	
	NewWindowhandler.registerOpen((url) => {
		openWindows.push(window.open(url));
	});

	NewWindowhandler.close(() => {
		
	});

	return (
		<React.Fragment>

		</React.Fragment>
	);
};

export default NewWindow;