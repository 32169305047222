import React, { PureComponent } from 'react';
import { AuthContext } from './AuthContext';


export class UserInfo extends PureComponent {



	static contextType = AuthContext;






	render() {
		
		return (this.context.authenticated ?
			(
				<div style={this.props.style}>
					{this.context.user.displayName}
				</div>
			) :
			(
				null

			)
		);
	}

}