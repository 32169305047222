import React, { PureComponent } from 'react';
import { AuthContext } from './AuthContext';
import { firebaseAuthHelper } from './firebaseAuthHelpers';

import { RoundedSmallButton } from '../common-elements/Button';
export class LogOut extends PureComponent {

	
	static contextType = AuthContext;


	signOut() {

		firebaseAuthHelper.signOut().then(() => {
			if (typeof this.props.onSignOut === 'function') {

				this.props.onSignOut();
			}
		}).catch((e) => {
			if (typeof this.props.onSignOutError === 'function') {
				this.props.onSignOutError(e);
			}
		});
	}

	render() {
		return (this.context.authenticated ?
			<RoundedSmallButton onClick={() => { this.signOut() }}>LOGOUT</RoundedSmallButton>
			
		: null);
	}

}