window.global = window.global || {};


class NewWindowHandler {
	openCallback = null;
	closeCallback = null;
	registerOpen(callback) {
		this.openCallback = callback;
	}

	registerClose(closeCallback) {
		this.closeCallback = closeCallback;
	}

	open(url) {
		if (typeof this.openCallback === 'function') {
			
			this.openCallback(url);
		}
	}

	close() {
		if (typeof this.closeCallback === 'function') {
			this.closeCallback();
		}
	}
}

const newWindowHandler = new NewWindowHandler();

window.global.newWindowHandler = newWindowHandler;
export default newWindowHandler;
