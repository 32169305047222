
import * as firebase from 'firebase/app';
import { app } from './../firebase/firebaseInit';
import 'firebase/firestore';

const log = console.log;
const warn = console.warn;
const error = console.error;

class FirebaseLogger {
    db = null;
    baseLog = null;
    baseWarn = null;
    baseError = null;
    constructor(log, warn, error) {
        this.db = firebase.firestore(app);
        this.baseLog = log;
        this.baseWarn = warn;
        this.baseError = error;
        this.initGlobalLogging();
        this.overrideLog();
    }

    init(){
        
    }

    overrideLog() {
        const th = this;
        console.error = function () {
            th.baseError.apply(console, arguments);
            th.error.apply(th, arguments);
        };
    }

    getMessage(args) {
        let message = '';
        for (const arg of args) {
            message += ' ';
            message += JSON.stringify(arg);
        }
        return message;
    }
    getLogMessage(level, message) {
        return {
            message: message,
            date: new Date(),
            userAgent: navigator ? navigator.userAgent : '',
            level: level,
            url: window.location.href,
            host: window.location.host,
            port: window.location.port
        }
    }
    log() {
        try {
            const message = this.getMessage(arguments);
            this.db.collection("logs").add(this.getLogMessage('log', message))
                .catch((error) => {
                    this.baseError("Error writing document: ", error);
                });
        }
        catch (e) {

        }
        finally {

        }

    }


    error() {
        try {
            const message = this.getMessage(arguments);
            this.db.collection("logs").add(this.getLogMessage('error', message))
                .catch((error) => {
                    this.error("Error writing document: ", error);
                });
        }
        catch (e) {

        }
        finally {

        }

    }

    initGlobalLogging() {
        window.onerror = (message, url, lineNumber) => {
            this.log(message + ' url: ' + url + ' lineNumber ' + lineNumber);
            return true;
        };
    }


}


export const Logger = new FirebaseLogger(log, warn, error);