import React, { Component } from 'react';

import 'firebase/auth';
import { withRouter } from 'react-router-dom'
import { UnityLoader } from './../unityLoader/UnityLoader';

import MalibuCountdown from './../common-elements/countdown/MalibuCountdown';
import { UserInfo } from './../auth/UserInfo';
import { LogOut } from '../auth/LogOut';
import Splash from './../common-elements/Splash';
import StartPageContainer from './../startpage/StartPageContainer';
import StartPageContent from './../startpage/StartPageContent';
import Welcome from './../startpage/Welcome';
import LogoContainer from './../startpage/Logo';
import { MarginBottom } from './../utils/LayoutHelpers';
import { firebaseAuthHelper } from '../auth/firebaseAuthHelpers';
import HeaderOverGame from '../common-elements/HeaderOverGame';
import { GridThreeColumnContainer } from './../common-elements/Grid';
import { XhrInterceptorInstance } from '../utils/xhrinterceptor';
import ProgressBar from './../common-elements/ProgressBar';
import ImageBackground from './../common-elements/ImageBackground';
import info_blockA from './MBWagendablock1.png';
import info_blockB from './MBWagendablock2.png';



import './Authenticated.css';

class Authenticated extends Component {
	onAuthStateChangeSub = null;
	constructor() {
		super();
		
		this.state = { eventStarted: false, eventCountdownComplete: false, codeLoadingProggress: 0, frameworkLoadingProgress: 0, dataLoadingProgress: 0 };
		XhrInterceptorInstance.progressCallback('/Build/Build/Build.wasm.code.unityweb', (progress) => {
			const result = this.calculateProgress(progress);
			if (result) {
				this.setState({ codeLoadingProggress: result });
			}
		});

		XhrInterceptorInstance.progressCallback('/Build/Build/Build.wasm.framework.unityweb', (progress) => {
			const result = this.calculateProgress(progress);
			if (result) {

				this.setState({ frameworkLoadingProgress: result });
			}
		});


		XhrInterceptorInstance.progressCallback('/Build/Build/Build.data.unityweb', (progress) => {

			const result = this.calculateProgress(progress);
			if (result) {

				this.setState({ dataLoadingProgress: result });
			}

		});



		XhrInterceptorInstance.progressCallback('/Build/Build/Build_test.wasm.code.unityweb', (progress) => {

			const result = this.calculateProgress(progress);
			if (result) {

				this.setState({ codeLoadingProggress: result });
			}
		});

		XhrInterceptorInstance.progressCallback('/Build/Build/Build_test.wasm.framework.unityweb', (progress) => {

			const result = this.calculateProgress(progress);
			if (result) {

				this.setState({ frameworkLoadingProgress: result });
			}
		});


		XhrInterceptorInstance.progressCallback('/Build/Build/Build_test.data.unityweb', (progress) => {

			const result = this.calculateProgress(progress);
			if (result) {

				this.setState({ dataLoadingProgress: result });
			}

		});


		this.unsubscribeToAuthStateChange();
		this.onAuthStateChangeSub = firebaseAuthHelper.onAuthStateChanged((user) => {
			if (user) {

			} else {
				this.props.history.push('/');
				if (typeof this.props.onSignIn === 'function') {
					this.props.onSignIn(null);
				}
			}
		});
		this.unityLoaderRef = React.createRef();
		setTimeout(()=>{
			this.setState({
				codeLoadingProggress: 100,
				dataLoadingProgress: 100,
				frameworkLoadingProgress: 100

			});
		}, 30000);
	}

	calculateProgress(progress) {
		if (progress && typeof progress.loaded === 'number' && typeof progress.total === 'number') {
			let contentLength = progress.loaded;

			let contentLengthHeader = this.parseContentLengtFromHeaderKey(progress.target, 'Content-Length');
			let xDecrompressedContentLength = this.parseContentLengtFromHeaderKey(progress.target, 'x-decompressed-content-length');
			if ((progress.lengthComputable || progress.loaded == 100) && progress.total > 0) {
				contentLength = progress.total;
			}
			else if (contentLengthHeader > 0) {
				contentLength = contentLengthHeader;
			}
			else if (xDecrompressedContentLength > 0) {
				contentLength = xDecrompressedContentLength;
			}
			const roundedProgress = Math.round(progress.loaded / contentLength * 100);
			return roundedProgress > 100 ? 100 : roundedProgress;

		}
		return null;
	}

	parseContentLengtFromHeaderKey(response, key) {
		if(!response){
return 0;
		}
		const contentLengthHeader = response.getResponseHeader(key);

		try {
			return parseInt(contentLengthHeader, 10);
		} catch {
			return 0;
		}
	}
	componentWillUnmount() {
		this.unsubscribeToAuthStateChange();
	}

	unsubscribeToAuthStateChange() {
		if (this.onAuthStateChangeSub) {
			this.onAuthStateChangeSub();
		}
	}

	onSignOut() {
		this.unsubscribeToAuthStateChange();
		this.props.onSignOut();
	}

	fakeEndEventCountDown() {
		this.setState({ eventCountdownComplete: true, eventStarted: true });
		this.unityLoaderRef.current.sendEventStarted(true);
	}

	fakeStartCountdown() {
		this.setState({ eventCountdownComplete: false, eventStarted: false });
		this.unityLoaderRef.current.sendEventStarted(false);
	}

	render() {
		
		return (
			<StartPageContainer backgroundColor={'#34AFA6'}>
				<ImageBackground eventStarted={this.state.eventStarted}></ImageBackground>
				<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', position: 'absolute', zIndex: 9999, top: '60px', right: '40px' }}>
					<UserInfo style={{ marginRight: '20px', color: '#fff', fontSize: '25px' }}></UserInfo>
					<LogOut onSignOut={() => this.onSignOut()}></LogOut>
					{/* <RoundedSmallButton onClick={() => { this.fakeEndEventCountDown(); }}>stop</RoundedSmallButton>
					<RoundedSmallButton onClick={() => { this.fakeStartCountdown(); }}>start</RoundedSmallButton> */}
				</div>

				<MarginBottom margin={'50px'}>
					<LogoContainer small={!this.state.eventStarted}></LogoContainer>
				</MarginBottom>

				<StartPageContent>

					{this.state.eventStarted ?
						(
							<React.Fragment>

								<MarginBottom margin={'220px'}>
									<Welcome eventStarted={true}></Welcome>
								</MarginBottom>

							</React.Fragment>
						) :
						(
							<React.Fragment>
								<MarginBottom margin={'-100px'}>
									<Welcome eventStarted={false}></Welcome>
								</MarginBottom>


								<MarginBottom margin={'300px'}>
									<MalibuCountdown onEventStarted={() =>
										this.setState({ eventStarted: true })
									}
										complete={this.state.eventCountdownComplete}
									></MalibuCountdown>
								</MarginBottom>

							</React.Fragment>
						)
					}
					<Splash backgroundColor={'#34AFA6'} height={1050} splashTopPosition={-330}>
						{this.state.eventStarted ?
							(<MarginBottom margin={'120px'}>

								<HeaderOverGame>
									<span>Malibu Brand World is open!</span>
								</HeaderOverGame>
							</MarginBottom>) :
							(null)
						}
						<MarginBottom margin={'0px'}>

							{
								((this.state.frameworkLoadingProgress > 0 || this.state.codeLoadingProggress > 0 || this.state.dataLoadingProgress > 0) &&
									(this.state.frameworkLoadingProgress < 100 || this.state.codeLoadingProggress < 100 || this.state.dataLoadingProgress < 100)) ?
									(
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: '20px' }}>

											<div style={{ display: 'flex', flexDirection: 'column', height: '160px', color: '#fff' }}>
												<span>
													Loading code
											</span>
												<ProgressBar style={{ marginBottom: '20px' }} width={'500px'} color={'#fff'} backgroundColor={'#fff'} height={'20px'} progress={this.state.codeLoadingProggress}>

												</ProgressBar>

												<span>Loading framework</span>
												<ProgressBar style={{ marginBottom: '20px' }} width={'500px'} color={'#fff'} backgroundColor={'#fff'} height={'20px'} progress={this.state.frameworkLoadingProgress}>

												</ProgressBar>
												<span>Loading data</span>
												<ProgressBar style={{ marginBottom: '20px' }} width={'500px'} color={'#fff'} backgroundColor={'#fff'} height={'20px'} progress={this.state.dataLoadingProgress}>

												</ProgressBar>
											</div>

										</div>
									)
									:
									null
							}
						</MarginBottom>
						<UnityLoader ref={this.unityLoaderRef}>
						</UnityLoader>
					</Splash>
					<div style={{
						display: 'flex', flexDirection: 'row', justifyContent: 'center', color:
							"#fff"
					}}>
						<div style={{ maxWidth: '800px', width: '80%' }}>
							<div style={{ paddingBottom: '20px' }}>
								<h2>About the game</h2>
								<p>
									The game will open on Monday 29th November when the Brand Workshop starts.
									Once you enter the game you will get a short walkthrough of the functions.
									You will also be able to see your personal agenda throughout the workshop and you can click in the calendar to take you directly to the room or area where the different events take place.
									In each area and room there is a group chat so you can write to the people who are in the same area or room as you.
									If you want a private chat with someone just click on their avatar and ”request private chat”.
									Now let’s all enjoy some virtual sunshine together!
								</p>
							</div>
							<div>
								<h2>
									GET TO KNOW MALIBU BRAND WORLD
								</h2>
							</div>
							<GridThreeColumnContainer>
								<div>
									<h3>MALIBU CITY</h3>
									<p>
										<i>Colourful main city areas with skyscrapers, commercial buildings and the like, an inspiring modern city. Future-leaning with a sustainable feel as an ideal city center.</i>
									</p>
									<h4>Woke university</h4>
									<p>
										The city’s top University and a great place to grow. Step into the grand classic entrance hall with stone stairs and three doors leading to Hall A, Hall B and Hall C.
									</p>
									<h4>DOMINO'S</h4>
									<p>
										The spot to grab a slice and hangout with your friends, don't miss out on the arcade game, who will get the highest score?
									</p>
									<h4>The T</h4>
									<p>
										Coolest rooftop bar & café around where the city’s young people come to enjoy a drink in the sun or watch the sunset while ”spilling the T” with their friends.
									</p>
								</div>
								<div>
									<h3>MALIBU BEACH</h3>
									<p>
										<i>
											Summery beach side area adjacent to the city with water stretching out beyond reach, palm trees, greenery and buildings and social areas on the land side.

									</i>

									</p>
									<h4>Chic Shack</h4>
									<p>
										Popular restaurant by the beach strip claiming both oxymoronic name and vision of being both nice and rough, tapping into the current trends and appealing to the young spirits of the city.

									</p>
									<h4>The pier</h4>
									<p>
										The city’s famous pier stretching into the sea, an immensely popular place for groups of young people to gather and hangout, show off their latest dance moves around the portable UEboom speaker and bottle of Malibu someone brought, or just their #ootd in a new post for the feed taken at the golden hour.

									</p>
									<h4>Malibu beach club</h4>
									<p>
										The ultimate beach club for good times, good parties and good vibes with bespoke day parties and events that turned it into a modern classic.

									</p>
								</div>
								<div>
									<h3>MALIBU HILLS</h3>
									<p>
										<i>
											The ideal residential area next to the city with parks, greenery and houses of which one main prominent villa belongs to William (or rather his parents, William is a top 5 male Gen Z name).

									</i>

									</p>
									<h4>The backyard</h4>
									<p>
										Here’s where William likes to hang out with his friends on sunny days by the pool and serve Malibu drinks on the terrace.

									</p>
									<h4>The kitchen</h4>
									<p>
										When it’s time for refreshments or the need to cool off in an air conditioned room William and his friends tend to gather in the kitchen.

									</p>
									<h4>The living room</h4>
									<p>
										Switching up the backyard hangouts with some games is always appreciated. Here in the living room William has a pool table as well as a big screen TV, complete with the latest video games of course.

									</p>
								</div>
							</GridThreeColumnContainer>
							<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '50px' }}>
								<img alt="info" src={info_blockA} style={{ width: '100%', marginBottom: '20px' }} />
								<img alt="info" src={info_blockB} style={{ width: '100%' }} />
							</div>
						</div>
					</div>
					<div style={{ height: '200px', overflow: 'hidden' }}>
						<Splash backgroundColor={'#34ADA0'} height={1050} splashTopPosition={-100}>

						</Splash>
					</div>
				</StartPageContent>
			</StartPageContainer>
		)
	}
}

export default withRouter(Authenticated);
