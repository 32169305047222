




import styled from 'styled-components'
const CenterContainer = styled.div`
	display: flex; 
	flex-direction: row;
	justify-content: center;
	position: relative;
	
`;

const MarginBottom = styled.div`
	display: block;
	position: relative;
	margin-bottom: 20px;
	${props => props.margin
		? `margin-bottom: ${props.margin};` : `
  	`}
`;

export { CenterContainer, MarginBottom };
