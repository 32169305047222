import styled from 'styled-components'


const StartPageContainer = styled.div`
	position: relative;
	${props => props.backgroundColor ? `
		background-color: ${props.backgroundColor};
	` : ``}

`;


export default StartPageContainer;