import firebaseConfig from './firebaseConfig';
import * as firebase from 'firebase/app';


const app = firebase.initializeApp(firebaseConfig);

const a = firebase.auth();


export const auth = a;
export { app };
//  var user = "";

//var database = firebaseDatabase.database();

