import styled from 'styled-components'
import React from 'react';



const RelativeContainer = styled.div`
	position: relative;
	
	width: 100%;
	overflow: visible;
	

		
	${props => props.maxHeight ?
		`
		max-height: ${props.maxHeight}px;
		
		` :
	`max-height: 1000px;`}

`;

const SplashContainer = styled.div`
	position: relative;
	
	width: 100%;
	overflow: hidden;
	${props => props.splashTopPosition ?
		`
		top: ${props.splashTopPosition}px;
		
		` :
		`top: 100px;`}
	${props => props.height ?
		`
		height: ${props.height}px;
		
		` :
		`height: 1500px;`}

`;

const SplashRotated = styled.div`
position: absolute;


transform: rotate(-2deg);
background-color: #34ADA0;

width: 250%;
left: -200px;
	${props => props.backgroundColor ?
		`
		background-color: ${props.backgroundColor};
		
		` :
	``}
	${props => props.splashTopPosition ?
		`
		top: ${props.splashTopPosition}px;
		
		` :
		`top: 100px;`}
		${props => props.height ?
			`
			height: ${props.height}px;
			
			` :
			`height: 800px;`}
	
		
`;

const SplashContent = styled.div`
	position: absolute;
	top: 0px;
	width: 100%;
	height: 400px;
	

`;


const Splash = (props) => {

	return (

		<RelativeContainer maxHeight={props.height}>
			<SplashContainer height={props.height + 700} splashTopPosition={props.splashTopPosition}>
				<SplashRotated backgroundColor={props.backgroundColor} height={props.height} >

				</SplashRotated>
			</SplashContainer>
			<SplashContent>
				<div>
					{props.children}
				</div>
			</SplashContent>
		</RelativeContainer>

	);
}
export default Splash;




