import styled from 'styled-components'
import React from 'react';
import logo2 from './mabrawoLogo_Large2.png';

const WelcomeContainer = styled.div`
	display: flex; 
	flex-direction: row;
	justify-content: center;
`;


const width = 1038;
const height = 432;

const WelcomeText = styled.div`
	background-image: url(${logo2});
	
	background-size: contain;
	background-repeat: no-repeat;
	${props =>
		props.small ?
			`
			width: ${width / 3}px;
			height: ${height / 3}px;
		` :
			`
			width: ${width}px;
			height: ${height}px;
		`
	}

`;

const Welcome = (props) => {

	return (
		<React.Fragment>
			<WelcomeContainer>

				<WelcomeText small={props.eventStarted}>

				</WelcomeText>
			</WelcomeContainer>
		</React.Fragment>
	);
}
export default Welcome;
export { WelcomeText };