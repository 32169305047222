import React, { PureComponent } from 'react';
import { AuthContext } from './../auth/AuthContext';

import Unity, { UnityContent } from "react-unity-webgl";
import { firebaseAuthHelper } from './../auth/firebaseAuthHelpers';
import { FullscreenButton } from './FullscreenButton';


const unityContent = new UnityContent(
	"Build/Build/Build.json",
	"Build/Build/UnityLoader.js"
);



export class UnityLoader extends PureComponent {



	static contextType = AuthContext;
	isFullScreen = false;

	constructor() {
		super();
		const windowData = this.getWindowData();
		const calculatedWidth = (windowData.screen.width * 0.6);
		
		const calculatedHeight = calculatedWidth / (16 / 9);
		this.state = { fromUnity: "nothing from unity", calculatedWidth: calculatedWidth, calculatedHeight: calculatedHeight, unityLoading: true };
		unityContent.on("loaded", () => {
			firebaseAuthHelper.onAuthStateChanged(() => {
				if (this.context.authenticated) {
					this.sendUser();
					this.sendWindowSize();
				}
			});

		});
		window.addEventListener('resize', () => {
			this.sendWindowSize();

		});
		window.global.entryPointReact.registerHelloReact((value) => this.helloReactFromUnity(value));
		window.global.entryPointReact.registerUnityLoading((value) => this.unityLoading(value));
		setTimeout(() => {
			this.unityLoading(false);
		}, 100000)

	}

	helloReactFromUnity(value) {
		this.setState({ fromUnity: 'this came from unity: ' + value });
	}

	unityLoading(loading) {
		this.setState({ unityLoading: loading });
	}
	componentDidCatch(error, info) {
		console.error(error, info);
	}


	componentWillUnmount() {
		try {
			unityContent.remove();
		} catch (e) {
			console.error(e);
		}

	}

	sendUser() {
		try {
			unityContent.send("ClientNetworkManager(Clone)", "SendValidationToServer", JSON.stringify(this.context));
		} catch (e) {
			console.error(e);
		}
	}

	sendEventStarted(started) {
		try {
			unityContent.send("JavaScript", "SetEventStarted", JSON.stringify(started));
		} catch (e) {
			console.error(e);
		}
	}

	getWindowData() {
		const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
		const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
		const data = {
			screen: {
				width: window.screen.width,
				height: window.screen.height
			},
			viewport: {
				width: vw,
				height: vh
			},
			devicePixelRatio: window.devicePixelRatio
		};
		return data;
	}
	sendWindowSize() {

		const data = this.getWindowData();
		try {
			unityContent.send('JavaScript', 'SetScreenData', JSON.stringify(data));
		} catch (e) {
			console.error(e);
		}
	}


	render() {


		return (this.context.authenticated ?
			(
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>


					<div style={{ position: 'relative', display: 'block', margin: 'auto', marginBottom: '100px', width: this.state.calculatedWidth + 'px', height: this.state.calculatedHeight + 'px' }}>

						{
							this.state.unityLoading ? (<div style={{
								position: 'absolute',
								width: '100%',
								height: '100%',
								zIndex: 99,
								color: '#fff',
								backgroundColor: '#34AFA6',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								fontSize: '100px'
							}}>
								Loading...
							</div>) : null
						}
						<Unity unityContent={unityContent} width={this.state.calculatedWidth} height={this.state.calculatedHeight} />

					</div>



				</div>
			) :
			(
				null
			)
		);
	}


}