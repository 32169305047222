import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import 'firebase/auth';

import { Login } from '../auth/Login';

import MalibuCountdown from './../common-elements/countdown/MalibuCountdown';

import Splash from './../common-elements/Splash';
import StartPageContainer from './StartPageContainer';
import StartPageContent from './StartPageContent';
import Welcome from './Welcome';
import LogoContainer from './Logo';
import { CenterContainer, MarginBottom } from '../utils/LayoutHelpers';
import { AuthContext } from '../auth/AuthContext';
import ImageBackground from './../common-elements/ImageBackground';
class LandingPage extends Component {

	constructor() {
		super();
		this.state = { eventStarted: false };

	}

	signIn(user) {
		this.props.onSignIn(user);
		if (user) {
			this.props.history.push('/authenticated');
		}
		
	}

	onSignInError(e) {
		if (typeof this.props.onSignInError === 'function') {
			this.props.onSignInError(e);
		}

	}

	userUpdated(user) {
		if (typeof this.props.userUpdated === 'function') {
			this.props.userUpdated(user);
		}
		
	}
	static contextType = AuthContext;
	render() {
		return (
			
				<StartPageContainer backgroundColor={'#0BA49B'}>

					<ImageBackground></ImageBackground>
					
					<LogoContainer></LogoContainer>
					<StartPageContent>

						<Welcome eventStarted={this.state.eventStarted}></Welcome>
						<MarginBottom margin={'60px'}>
							<MalibuCountdown onEventStarted={() => this.setState({ eventStarted: true })}></MalibuCountdown>
						</MarginBottom>

						<CenterContainer>
							<MarginBottom>
								<Login onSignIn={(data) => this.signIn(data)} onSignInError={(e) => this.onSignInError(e)} onRefreshToken={(user) => this.userUpdated(user)}>

								</Login>
							</MarginBottom>
						</CenterContainer>

				</StartPageContent>
				<div style={{ height: '200px', overflow: 'hidden' }}>
						<Splash backgroundColor={'#FFBE00'} height={1050} splashTopPosition={-100}>

						</Splash>
					</div>

				</StartPageContainer>
		)
	}

}


export default withRouter(LandingPage);
