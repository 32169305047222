import React, { Component } from 'react';
import styled from 'styled-components'
const TimeValue = styled.div`
	text-align: center;
	color: #FC7700;
	font-family: 'Arial';
	
`;
const TimeUnit = styled.div`
	text-align: center;
	color: #FC7700;
	
`;

const TimeUnitValue = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-width: 640px;
	font-size: 40px;
	
`;



export class FormattedCountdown extends Component {

	constructor(props) {
		super(props)
		if (props.api.isCompleted() &&
			typeof props.onComplete === 'function') {
			props.onComplete();
		}
	}

	render() {



		return (
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
				<Container>
					<TimeUnitValue>
						<TimeUnit>
							Days
						</TimeUnit>
						<TimeValue>
							{this.props.days.toString()}
						</TimeValue>

					</TimeUnitValue>
					<TimeUnitValue>
						<TimeUnit>
							Hours
					</TimeUnit>
						<TimeValue>
							{this.props.hours.toString().length === 1 ? '0' + this.props.hours.toString() : this.props.hours.toString()}
						</TimeValue>
					</TimeUnitValue>
					<TimeUnitValue>
						<TimeUnit>
							Minutes
					</TimeUnit>
						<TimeValue>
							{this.props.minutes.toString().length === 1 ? '0' + this.props.minutes.toString() : this.props.minutes.toString()}
						</TimeValue>
					</TimeUnitValue>
					<TimeUnitValue>
						<TimeUnit>
							Seconds
					</TimeUnit>
						<TimeValue>
							{this.props.seconds.toString().length === 1 ? '0' + this.props.seconds.toString() : this.props.seconds.toString()}
						</TimeValue>
					</TimeUnitValue>
				</Container>
			</div>
		)
	}

}


export default FormattedCountdown;
