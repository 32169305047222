
let oldXHROpen = window.XMLHttpRequest.prototype.open;

let XhrInterceptorInstance = null;

class XhrProgressInterceptor {



	urlCallbacks = {};
	constructor() {
		window.XMLHttpRequest.prototype.open = function (method, url, async, user, password) {
			// do something with the method, url and etc.
			try {
				XhrInterceptorInstance.handleOpen(method, url, async, user, password, this);
			} catch (e) {
				console.error(e);
			}

			return oldXHROpen.apply(this, arguments);
		};

	}

	progressCallback(url, callback) {
		const keys = Object.keys(this.urlCallbacks);
		const urlsThatMatch = keys.filter(k => k.indexOf(url) >= 0);
		if (urlsThatMatch.length > 0) {
			urlsThatMatch.forEach(element => {
				this.urlCallbacks[element] = callback;
			});
		}
		else {
			this.urlCallbacks[url] = callback;
		}
	}

	urlsThatMatch(url) {
		const keys = Object.keys(this.urlCallbacks);
		const urlsThatMatch = keys.filter(k => k.indexOf(url) >= 0);
		const result = [];
		urlsThatMatch.forEach((url) => {
			result.push({ url: url, callback: this.urlCallbacks[url] });
		});

		return result;
	}
	handleOpen(method, url, async, user, password, client) {
		const urlsCallbacks = this.urlsThatMatch(url);
		if (urlsCallbacks && urlsCallbacks.length > 0) {
			this.interceptRequest(method, url, async, user, password, client, urlsCallbacks);

		}
	}

	interceptRequest(method, url, async, user, password, client, urlsCallbacks) {
		let currentProgress = null;
		
		client.onprogress = function (pe) {
			urlsCallbacks.forEach((urlCallBack) => {

				currentProgress = pe;
				urlCallBack.callback(pe);

			});
		};
		client.addEventListener('load', () => {
			urlsCallbacks.forEach((urlCallBack) => {
				if (client.status) {
					urlCallBack.callback({loaded: 100, total: 100});
				} else {
					console.error("failed to load unity files", url, 'statuscode: ' + client.status);
					urlCallBack.callback(currentProgress);
				}

			});
		});
	}



}

XhrInterceptorInstance = new XhrProgressInterceptor();
export { XhrInterceptorInstance };