
import { provider } from './firebaseProvider';
import { auth } from '../firebase/firebaseInit';
import * as firebase from 'firebase/app';
import { User } from './User';
import { AuthLocalStorageInstance } from './authLocalstorage';


class FirebaseAuthHelper {

	additionalUserInfo = null;
	msIdToken = null;
	intervalId = null;
	idToken = null;
	signIn() {
		return auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
			return auth.signInWithPopup(provider).then((data) => {
				return this.getIdTokenResult().then(() => {
					this.additionalUserInfo = data.additionalUserInfo;
					this.msIdToken = data.credential.idToken;
					AuthLocalStorageInstance.saveMsIdToken(this.msIdToken);
					AuthLocalStorageInstance.saveAdditionalUserInfo(this.additionalUserInfo);
				});
			}).catch((e) => console.error(e));
		});
	}

	onAuthStateChanged(callback, errorCallback) {

		return auth.onAuthStateChanged((user) => {
			if (user) {
				return this.getIdTokenResult().then(() => {
					this.loadTokenAndAddtionalUserInfo();
					this.getLightUser().then((user2) => {
						this.setUpRefresh();

						if (typeof callback === 'function') {
							callback(user2);
						}
					});

				});
			} else {
				if (typeof callback === 'function') {
					callback(user);
				}
			}
		},
			(e) => typeof errorCallback === 'function' ? errorCallback(e) : undefined);
	}
	setUpRefresh() {
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
		this.intervalId = setInterval(() => {

			this.refreshAuthToken();
		}, 1000 * 60 * 60);
	}

	loadTokenAndAddtionalUserInfo() {
		this.msIdToken = AuthLocalStorageInstance.getMsIdToken();
		this.additionalUserInfo = AuthLocalStorageInstance.getAdditionalUserInfo();
	}

	getCurrentUser() {
		return auth.currentUser;
	}
	signOut() {
		return auth.signOut().then(() => {
			this.additionalUserInfo = null;
			this.msIdToken = null;
			this.idToken = null;
			clearInterval(this.intervalId);
			AuthLocalStorageInstance.removeMsIdToken();
			AuthLocalStorageInstance.removeAdditionalUserInfo();
		});
	}
	getLightUser() {
		if (!auth.currentUser) {
			return Promise.reject();
		}
		return auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
			const lightUser = new User(this.getCurrentUser(), this.idToken, this.msIdToken, this.additionalUserInfo);
			return lightUser;
		});

	}

	getIdTokenResult() {
		return auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
			if (!auth.currentUser) {
				return Promise.reject();
			}
			return auth.currentUser.getIdTokenResult(true).then(tokenResult => {
				this.idToken = tokenResult.token;
				return this.idToken;
			});
		});
	}


	refreshAuthToken() {
		firebaseAuthHelper.getIdTokenResult().then(() => {
			return this.getLightUser();
		}).catch((e) => {
			console.error(e);
			return this.signOut();
		});
	}
}




export const firebaseAuthHelper = new FirebaseAuthHelper();

