window.global = window.global || {};


class EntryPointReact {

	helloReactCallback = null;
	registerHelloReact(callback) {
		this.helloReactCallback = callback;
	}

	unityLoadingCallback = null;
	registerUnityLoading(callback) {
		this.unityLoadingCallback = callback;
	}

	helloReact(value) {
		if (typeof this.helloReactCallback === 'function') {
			this.helloReactCallback(value);
		}
	}

	unityLoading(loading) {
		if (typeof this.unityLoadingCallback === 'function') {
			this.unityLoadingCallback(loading);
		}
	}

	

}

window.global.entryPointReact = new EntryPointReact();