import React from 'react';


export const notAuthenticated = {
	authenticated: false,
	user: null,
	
};

export const AuthContext = React.createContext(notAuthenticated);



