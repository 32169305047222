

import * as firebase from 'firebase/app';
const provider = new firebase.auth.OAuthProvider('microsoft.com');
provider.setCustomParameters({
	
	//tenant: '02d7fa3a-68c5-4155-9050-a14b16965e8f',
	login_hint: 'someone@spree.se'
});
provider.addScope('User.Read');
provider.addScope('email');
provider.addScope('openid');
provider.addScope('profile');


export { provider };