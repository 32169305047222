window.global = window.global || {};


class Popup {
	openCallback = null;
	closeCallback = null;
	registerOpen(callback) {
		this.openCallback = callback;
	}

	registerClose(closeCallback) {
		this.closeCallback = closeCallback;
	}

	open(obj) {
		if (typeof this.openCallback === 'function') {
			let parsedObj = null;
			if (obj) {
				 parsedObj = JSON.parse(obj);
			}
			this.openCallback(parsedObj);
		}
	}

	close() {
		if (typeof this.closeCallback === 'function') {
			this.closeCallback();
		}
	}
}

const popup = new Popup();

window.global.popup = popup;
export default popup;
