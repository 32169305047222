import LoginButtonNoText from './loginButton_noText.png';
import LogoutButtonNoText from './LogoutButton_noText.png';

import styled from 'styled-components'
const Button = styled.button`
border: 0;
border-radius: 0.25rem;
background: #1E88E5;
color: white;
font-size: 1rem;
line-height: 1.2;
white-space: nowrap;
text-decoration: none;
padding: 0.25rem 0.5rem;
margin: 0.25rem;
cursor: pointer;
font-family: 'Cubano Nuevo Italic';
	
`;


export const RoundButton = styled.button`
border: 0;
border-radius: 10px;

background: #1E88E5;
color: white;
font-size: 16px;
height: 30px;
line-height: 1.2;
white-space: nowrap;
text-decoration: none;
padding-left:20px;
padding-right:20px;
cursor: pointer;
font-family: 'Cubano Nuevo Italic';
	
`;

const BigButton = styled.button`
border: 0;
border-radius: 0.25rem;
background: #1E88E5;
color: white;
font-size: 2rem;
line-height: 1.2;
white-space: nowrap;
text-decoration: none;
padding: 0.25rem 0.5rem;
margin: 0.25rem;
cursor: pointer;
transform: rotate(-3deg);
font-family: 'Cubano Nuevo Italic';
`;


const RoundedBigButton = styled.button`
	border: 0;
	background: none;
	cursor: pointer;
	width: 262px;
	height: 114px;
	display:flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-image: url(${LoginButtonNoText});
	background-size: contain;
	background-repeat: no-repeat;
	font-family: 'Malibu Handwritten 3', 'Arial';
	font-size: 60px;
	color: #FFF;

`;

const RoundedSmallButton = styled.button`
	border: 0;
	background: none;
	cursor: pointer;
	width: 168px;
	height: 56px;
	display:flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-image: url(${LogoutButtonNoText});
	background-size: contain;
	background-repeat: no-repeat;
	font-family: 'Cubano Nuevo Italic', 'Arial';
	font-size: 25px;
	color: #FFF;

`;


export default Button;

export { BigButton, RoundedBigButton, RoundedSmallButton };