
import styled from 'styled-components'
const GridTwoColumnContainer = styled.div`
	
	grid-template-columns: auto auto;
	  column-gap: 40px;
	  display: grid
	
`;


const GridThreeColumnContainer = styled.div`
	
	grid-template-columns: auto auto auto;
	  column-gap: 40px;
	  display: grid
	
`;

export default GridTwoColumnContainer;
export { GridThreeColumnContainer }

