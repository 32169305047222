import React, { Component } from 'react';

import styled from 'styled-components'
const ProgressBarContainer = styled.div`
	position: relative;
	width: auto;
	border: 1px solid #000;
	${props => (typeof props.height !== 'undefined') ? `
	height: ${props.height};	
	` : ``}
	${props => (typeof props.width !== 'undefined') ? `
	width: ${props.width};	
	` : ``}
	overflow:visible;
	background-color: ${props => props.backgroundColor};
	border-radius: 5px 5px;
	border: 2px solid #fff;
`;

const ProgressBarProggression = styled.div`
position: relative;
height: 100%;
${props => (typeof props.width !== 'undefined') ? `
	width: ${props.width}%;
` : ``}
${props => props.color ? `
	background-color: ${props.color};	
	` : ``}
	
`;


const LoaderChildrenContainer = styled.div`
position: absolute;
top: 0px;
left: 1px;
`;
class ProgressBar extends Component {

	constructor(props) {
		super();
	}


	render() {
		return (
			<ProgressBarContainer style={this.props.style} width={this.props.width} height={this.props.height} >
				<ProgressBarProggression width={this.props.progress} color={this.props.color}>

				</ProgressBarProggression>
				<LoaderChildrenContainer>
					{this.props.children}
				</LoaderChildrenContainer>
			</ProgressBarContainer>
		)
	}
}

export default ProgressBar;
