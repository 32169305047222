import React, { Component } from 'react'
import { Logger } from './firebaseLogging';
export class ErrorBoundary extends Component {
    constructor() {
        super();
        Logger.init();
    }
  
    componentDidCatch(error, info) {
      console.error(error, info);
    }
  
    render() {
      return this.props.children;
    }
  }