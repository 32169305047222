export class User {
	token = null;
	msIdToken = null;
	displayName = null;
	email = null;
	phoneNumber = null;
	photoUrl = null;
	providerId = null;
	uid = null;
	refreshToken = null;
	constructor(obj, token, msIdToken, additionalUserInfo) {
		if (token) {
			this.token = token;
		}
		if (msIdToken) {
			this.msIdToken = msIdToken;
		}
		if (obj) {
			this.displayName = obj.providerData[0].displayName;
			this.email = obj.providerData[0].email;
			this.phoneNumber = obj.providerData[0].phoneNumber;
			this.photoUrl = obj.providerData[0].photoUrl;
			this.providerId = obj.providerData[0].providerId;
			this.uid = obj.providerData[0].uid;
			this.refreshToken = obj.refreshToken;

		}
		if (additionalUserInfo && additionalUserInfo.profile) {
			
		}

	}
}