class AuthLocalStorage {

	saveMsIdToken(idToken) {
		window.localStorage.setItem('msIdToken', idToken);
	}

	getMsIdToken() {
		const result = window.localStorage.getItem('msIdToken');
		
		return result;
	}
	
	removeMsIdToken() {
		window.localStorage.removeItem('msIdToken');
	}

	save(user) {
		window.localStorage.setItem('user', JSON.stringify(user));
	}

	saveAdditionalUserInfo(additionalUserInfo) {
		window.localStorage.setItem('additionalUserInfo', JSON.stringify(additionalUserInfo));
	}

	getAdditionalUserInfo() {
		const result = window.localStorage.getItem('additionalUserInfo');
		if (result) {
			return JSON.parse(result);
		}
		return null;
	}

	removeAdditionalUserInfo() {
		window.localStorage.removeItem('additionalUserInfo');
	}

	get() {
		const user = window.localStorage.getItem('user');
		if (user) {
			return JSON.parse(user);
		}
		return null;
	}

	remove() {
		window.localStorage.removeItem('user');
	}
}



export const AuthLocalStorageInstance = new AuthLocalStorage();