import styled from 'styled-components'
import React from 'react';
import logo from './malibuLogo.png';

const LogoContainerRow = styled.div`
	display: flex; 
	flex-direction: row;
	justify-content: center;
	z-index: 999;
	position: relative;
	padding-top: 100px;
	
`;

const width = 138;
const height = 150;

const Logo = styled.div`
	background-image: url(${logo});
	width: ${width/1.5}px;
	height: ${height/1.5}px;
	background-size: contain;
	background-repeat: no-repeat;
	/*${props => props.small ? `
	 width: ${width/1.5}px;
	 height: ${height/1.5}px;
	 ` : ''}*/

`;

const LogoBackground = styled.div`
	
	display: flex; 
	flex-direction: row;
	justify-content: center;
	align-items: center;

`;


const LogoContainer = (props) => {

	return (

		<LogoContainerRow>
			<LogoBackground>
				<Logo small={props.small}></Logo>
			</LogoBackground>
		</LogoContainerRow>


	);
}

export default LogoContainer;
export { Logo, LogoBackground }