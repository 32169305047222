import React, { Component } from 'react';

import 'firebase/auth';

import './App.css';
import { Route, BrowserRouter as Router, Switch, } from "react-router-dom";
import { AuthContext, notAuthenticated } from './auth/AuthContext';
import LandingPage from './startpage/LandingPage';
import Authenticated from './Authenticated/Authenticated';
import { firebaseAuthHelper } from './auth/firebaseAuthHelpers';

import ControlledPopup from './utils/ControlledPopup';
import NewWindow from './utils/NewWindow';
import { ErrorBoundary } from './logging/ErrorBoaundry';


class App extends Component {

	onAuthStateChangeSub = null;
	constructor() {
		super();


		this.state = { auth: notAuthenticated };

		
		this.onAuthStateChangeSub = firebaseAuthHelper.onAuthStateChanged((user) => {

			if (user) {
				this.userUpdated(user);
			}
		});
	}

	componentWillUnmount() {
		if (this.onAuthStateChangeSub) {
			this.onAuthStateChangeSub();
		}
	}

	onSignInError(e) {
		console.error(e);
		this.setState({ auth: notAuthenticated });

	}

	onSignOut() {
		this.setState({ auth: notAuthenticated });
		window.location.reload();
	}

	userUpdated(user) {
		this.setState({ auth: { user: user, authenticated: !!user } });
	}

	logURL(requestDetails) {
		console.log("Loading: " + requestDetails.url);
	}


	render() {

		return (
			<ErrorBoundary>
				<AuthContext.Provider value={this.state.auth}>

					<Router>
						<Switch>
							<Route path="/authenticated" exact={true}>
								<Authenticated onSignOut={() => this.onSignOut()}></Authenticated>
							</Route>
							<Route path="/" exact={true}>
								<LandingPage onSignIn={(user) => this.userUpdated(user)}></LandingPage>
							</Route>

						</Switch>
					</Router>

					<ControlledPopup></ControlledPopup>
					<NewWindow></NewWindow>
					{/* <GitCommit></GitCommit> */}
				</AuthContext.Provider>
			</ErrorBoundary>
		)
	}

}

export default App;
