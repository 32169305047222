import React from 'react'
//import bk from './background.jpg';
//import bk2 from './MalibuBayBreezeUSLifestyle2.jpg'

import bk3 from './Malibu_Lemonade_UK_Lifestyle.jpg';
export default function ImageBackground(props) {

	
	const imgStyle = { width: '120%', marginLeft: '-10%' };
	if (props.eventStarted) {
		imgStyle.marginTop = '-268px';
	}else{
		imgStyle.marginTop = '0px';
	}
	return (
		<div style={{
			position: 'absolute',
			top: '0px',
			right: '0px',
			left: '0px',
			height: '1300px',
			overflow: 'hidden'
		}}>
			<img src={bk3} alt={'test'} style={imgStyle}></img>
		</div>
	)
}
