import React, { useState } from 'react';

import Popup from 'reactjs-popup';
//
import popup from '../unityLoader/Popup';
import 'reactjs-popup/dist/index.css';
import styled from 'styled-components'
import { RoundedSmallButton } from './../common-elements/Button';

const PopupContainer = styled.div`
	padding: 20px;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`;


const ControlledPopup = () => {
	const [open, setOpen] = useState(false);
	const closeModal = () => setOpen(false);
	const [content, setContent] = useState({ title: 'nothin', content: 'no content', closeButton: true });
	popup.registerOpen((popupData) => {
		setOpen(true);
		setContent({ title: 'nothin', content: 'no content', closeButton: true });
	});

	popup.registerClose(() => {
		closeModal();
	});


	return (
		<React.Fragment>
			<Popup open={open} closeOnDocumentClick onClose={closeModal}>
				<PopupContainer>
					<h2>
						{content.title}
					</h2>
					<div>
						<p>
							{content.content}
						</p>
						<ButtonContainer>
							<RoundedSmallButton onClick={closeModal}>OK</RoundedSmallButton>
						</ButtonContainer>
					</div>
				</PopupContainer>
			</Popup>
		</React.Fragment>
	);
};

export default ControlledPopup;